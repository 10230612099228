import { Component, OnInit, Input } from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-warning-noti',
  templateUrl: './warning-noti.component.html',
  styleUrls: ['./warning-noti.component.scss']
})
export class WarningNotiComponent implements OnInit {
  isChecked = false;
  @Input() id: any;
  constructor(
      private modalRef: NzModalRef<WarningNotiComponent>,
      public commonService: CommonService
  ) { }

  ngOnInit(): void {}

  accept(){
    if(this.isChecked) {
      localStorage.setItem('percentGift', JSON.stringify(this.isChecked));
    }
    this.modalRef.close(this.isChecked);
  }
}
