import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";

// tslint:disable-next-line:ban-types
declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticService {

  constructor() { }

  public event(action: string, payload: any) {
    console.log('bắn event', payload);
    console.log('action', action);
    gtag('event', action, payload);
  }

  public initialize() {
    // dynamically add analytics scripts to document head
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.googleAnalyticId}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticId}', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }
}
