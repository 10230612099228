<div class="container">
    <div nz-row class="content">
        <div nz-col nzXs="6">
            <img src="assets/images/warning-permission.png" />
        </div>
        <div nz-col nzXs="16">
            <span *ngIf="!isValue && !isSucces">Tất cả dữ liệu bạn thay đổi sẽ không  lưu. Bạn có chắc chắn muốn rời khỏi trang này?</span>
            <span *ngIf="isValue">Cấu hình chương trình chưa được lưu. Bạn có chắc chắn muốn rời khỏi trang này?</span>
            <span *ngIf="isSucces">Chương trình chưa có kịch bản trả thưởng. Bạn có chắc chắn muốn hoàn thành cấu hình chương trình không?</span>
        </div>
    </div>
    <div class="footerModal">
        <button class="btn back" nz-button nzType="default" (click)="submit('cancel')" style="margin-right: 5px;">
            Bỏ qua
        </button>
        <button nz-button [ngClass]="!isCheckAccountVietel ? 'ant-btn-primary' : 'viettel-button'" type="submit" (click)="submit('approve')">Đồng ý</button>
    </div>
</div>
