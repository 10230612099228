import { Component, OnInit } from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd';

@Component({
  selector: 'app-alert-retarget',
  templateUrl: './alert-retarget.component.html',
  styleUrls: ['./alert-retarget.component.scss']
})
export class AlertRetargetComponent implements OnInit {
  isShow = false;
  constructor(
      private modalRef: NzModalRef<AlertRetargetComponent>,
  ) { }

  ngOnInit(): void {
  }

  onShowModal() {
    this.isShow = true;
  }

  closeModal() {
    // this.isShow = false;
    this.modalRef.close('close');
  }

}
