import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';
@Injectable({
  providedIn: 'root'
})

export class CampaignSharedService extends BaseLoyaltyService {

  getCampaign(query?: any): Observable<any> {
    return this.get<any>(`/campaign`, { params: query });
  }

  charList(query?: any): Observable<any> {
    return this.get<any>(`/campaign/statistical/chart/for-main`, { params: query });
  }

  statistical(): Observable<any> {
    return this.get<any>(`/campaign/statistical/overview/for-main`);
  }

  createFallGift(body) {
    return this.post<any>('/campaign', body);
  }

  getDetailFallGift(id) {
    return this.get<any>(`/campaign/${id}`);
  }

  getDetailCampaign(id) {
    return this.get<any>(`/campaign/${id}`);
  }

  updateFallGift(id, body) {
    return this.put<any>(`/campaign/${id}`, body);
  }

  updateCampaign(id, body) {
    return this.put<any>(`/campaign/${id}`, body);
  }

  getGoogleSheet(id) {
    return this.get<any>(`/campaign/${id}/setting-ggsheets`);
  }

  postGoogleSheet(id,body){
    return this.post<any>(`campaign/${id}/setting-ggsheets`, body)
  }
}
