<form nz-form [formGroup]="frmAddGift" (ngSubmit)="submitForm()">
  <div class="contentModal">
    <nz-form-item>
      <nz-form-label>Tên quà (<span class="required">*</span>)</nz-form-label>
      <nz-form-control [nzErrorTip]="name">
        <input autocomplete="off" nz-input name="name" type="text" formControlName="name">
        <ng-template #name let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Vui lòng nhập tên quà</i></p>
          </ng-container>
          <ng-container *ngIf="control.hasError('maxlength')">
            <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Ảnh quà(<span class="required">*</span>)</nz-form-label>
      <nz-form-control>
        <app-i-image *ngIf="frmAddGift.get('type').value !== 'ICOIN'" [multiple]=false [maxImage]="1" [fileList]="images" fileType="image/jpeg;image/png;image/jpg"
          (change)="uploadImageAttribute($event)"></app-i-image>
        <img class="img-vnpoint" *ngIf="frmAddGift.get('type').value === 'ICOIN'" [src]="imgVNPOINT ? imgVNPOINT : 'assets/images/vn-point/VnPoint.png'"/>
        <i  *ngIf="frmAddGift.get('type').value !== 'ICOIN'" style="position: absolute;width: 100%;right: 0;top: 0px;">Kích thước tối ưu: Tỉ lệ ảnh 1:1</i>
        <i  *ngIf="frmAddGift.get('type').value === 'ICOIN'" style="display: block;">Kích thước tối ưu: Tỉ lệ ảnh 1:1</i>
        <ng-container *ngIf="isImage && frmAddGift.get('type').value !== 'ICOIN'">
          <p class="required" style="margin-top: -20px;margin-bottom: -10px;">
            <i>Vui lòng chọn ảnh</i>
          </p>
        </ng-container>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Loại quà (<span class="required">*</span>)</nz-form-label>
      <nz-form-control [nzErrorTip]="type_gift">
        <nz-select [nzDisabled]="gift" formControlName="type" id="type_gift" nzPlaceHolder="Chọn loại quà"
          (ngModelChange)="changeTypeGift($event)">
          <nz-option [nzHide]="hiddenOptionViettel(o)" *ngFor="let o of typeGifts" nzValue="{{o.value}}"
            nzLabel="{{o.label}}" nzDisabled="{{o.nzDisabled}}"></nz-option>
        </nz-select>
        <ng-template #type_gift let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Vui lòng chọn loại quà</i></p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Giá trị <span *ngIf="frmAddGift.value.type !== 'CONGRATULATION' && frmAddGift.value.type !== 'VNPAY_QR'">(<span class="required">*</span>)</span><i [hidden]="frmAddGift.value.type === 'CONGRATULATION'" nz-icon nzType="question-circle"
          nzTheme="outline" nz-tooltip [nzTooltipTitle]="tooltipiCoin"></i></nz-form-label>
      <div
        *ngIf="frmAddGift.value.type === 'PRODUCT' || frmAddGift.value.type === 'RECEIVE_STORE'">
        <nz-form-control [nzErrorTip]="icoin">
<!--          <input type="text" pattern="\d*" (blur)="checkNumber($event)" nz-input name="coin" formControlName="icoin">-->
          <input type="number" min="0" (change)="formatNumber($event, 'icoin')" pattern="^[0-9]*$" nz-input name="coin" formControlName="icoin">
          <ng-template #icoin let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>{{errMessage}}</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </div>

      <div *ngIf=" frmAddGift.value.type === 'VNPAY_QR'">
        <nz-form-control [nzErrorTip]="icoin">
          <input type="text" pattern="\d*" (blur)="checkNumber($event)" nz-input name="coin" formControlName="icoin">
          <ng-template #icoin let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>{{errMessage}}</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </div>

      <div *ngIf="frmAddGift.value.type === 'ICOIN' || frmAddGift.value.type === 'PHONE_CARD' || frmAddGift.value.type === 'DIGITAL_MONEY'">
        <nz-form-control [nzErrorTip]="icoin">
          <nz-select [nzDisabled]="typeForm!=='Thêm mới'" formControlName="icoin" nzShowSearch nzAllowClear nzPlaceHolder="Giá trị của quà">
            <nz-option *ngFor="let option of valueOptions"
              nzLabel="{{option.label | number}} {{frmAddGift.value.type === 'ICOIN' ? 'Điểm' : 'VNĐ'}}"
              [nzValue]="option.value" [nzDisabled]="typeForm !=='Thêm mới'"></nz-option>
          </nz-select>
          <ng-template #icoin let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>Vui lòng nhập giá trị quà</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </div>

      <label *ngIf="frmAddGift.value.type === 'CONGRATULATION'">
        <span>Không có giá trị</span>
      </label>
    </nz-form-item>

    <!-- Quà VNPAY_QR -->
    <nz-form-item *ngIf="frmAddGift.value.type === 'VNPAY_QR'">
      <nz-form-label>Mã chương trình VNPay (<span class="required">*</span>) </nz-form-label>
      <nz-form-control [nzErrorTip]="name">
        <input type="text" pattern="\d*" autocomplete="off" nz-input name="vnpay_campaign_id"
          formControlName="vnpay_campaign_id">
        <ng-template #vnpay_campaign_id let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Nhập mã chương trình VNPay</i></p>
          </ng-container>
          <ng-container *ngIf="control.hasError('maxlength')">
            <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="frmAddGift.value.type === 'VNPAY_QR'">
      <nz-form-label>Mã VNPay QR (<span class="required">*</span>)</nz-form-label>
      <nz-form-control [nzErrorTip]="name">
        <input autocomplete="off" nz-input name="vnpay_gift_code" formControlName="vnpay_gift_code">
        <ng-template #vnpay_gift_code let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Nhập mã chương trình VNPay</i></p>
          </ng-container>
          <ng-container *ngIf="control.hasError('maxlength')">
            <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <!-- end -->

    <nz-form-item>
      <nz-form-label>Mô tả</nz-form-label>
      <nz-form-control>
        <input [ngxSummernote]="config" type="text" nz-input formControlName="description" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="type">
      <nz-form-control>
        <label nz-checkbox formControlName="active">Hiển thị quà</label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="this.frmAddGift.value.type === 'PHONE_CARD'">
      <nz-form-control>
        <label nz-checkbox formControlName="manual_release">Duyệt quà</label>
      </nz-form-control>
      <i>Lưu ý: Khi kích hoạt Duyệt quà, quà tặng sau khi trúng sẽ cần thao tác duyệt để khách hàng có thể nhận thưởng</i>
    </nz-form-item>

<!--    value = Product: hiện vật-->
<!--    start Logistics-->
    <div *ngIf="frmAddGift.get('type').value === 'PRODUCT'">
      <nz-form-item>
        <nz-form-control>
          <nz-switch (ngModelChange)="onConfigLogistic()" formControlName="transport"></nz-switch> <label> Cấu hình vận chuyển</label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="frmAddGift.get('transport').value">
        <nz-form-label>Trọng lượng <span class="required">*</span></nz-form-label>
        <nz-form-control [nzErrorTip]="_weight">
          <nz-input-group nzAddOnAfter="g">
<!--              <input type="number" [min]="0" pattern="\d*" (change)="formatNumber($event, 'weight')" nz-input name="coin" formControlName="weight">-->
              <input type="number" placeholder="Nhập trọng lượng" nz-input formControlName="weight">
<!--              <ng-template #_weight let-control>-->
<!--                <ng-container *ngIf="control.hasError('required')">-->
<!--                  <p class="required"><i>{{errMessage}}</i></p>-->
<!--                </ng-container>-->
<!--              </ng-template>-->
<!--            <nz-input-number (change)="xxxxxxx($event)" style="width: 100%" nzPlaceHolder="Trọng lượng"  formControlName="weight" [nzStep]="1"></nz-input-number>-->
<!--            <input appLimitDirective [decimal]="3" type="text" style="width: 90%;" id="chance" nz-input placeholder="Nhập số xác suất"-->
          </nz-input-group>
          <ng-template #_weight let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>Vui lòng nhập trọng lượng</i></p>
            </ng-container>

            <ng-container *ngIf="control.hasError('min')">
              <p class="required"><i>Giá trị tối thiểu là 0</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
<!--    enf Logistics-->
  </div>

  <div class="footerModal">
    <button nz-button  [disabled]="!frmAddGift.valid" [ngClass]="!isCheckAccountVietel ? 'ant-btn-primary' : 'viettel-button'"
      type="submit">{{typeForm}}</button>
    <button nz-button nzType="default" type="button" (click)="cancel()" class="btn-df">Bỏ qua</button>
  </div>
</form>
