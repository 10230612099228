import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootService } from 'src/app/shared/services/root.service';
import { UpdateBusinessComponent } from 'src/app/shared/components/update-business/update-business.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd';
import { ModalOutDateComponent } from 'src/app/shared/modal-out-date/modal-out-date.component';
import { CommonService } from 'src/app/shared/services/common/common.service';
import * as moment from 'moment';
import { DateExpiredComponent } from 'src/app/shared/date-expired/date-expired.component';
import { CampaignService } from './campaign/services/campaign.service';
declare var $: any;

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  isVisible = false;
  changeTab = false;
  isCheckTeko = false;
  unsubscribe$ = new Subject<void>();
  timeToday = new Date();
  subscriptionCampaign: Subscription;
  menu = [
    {
      title: 'Thống kê loyalty',
      routerLink: '/loyalty/home',
      class: 'iconMenu2',
      nzType: 'line-chart',
      nzTheme: 'outline',
      isChildren: false,
      isViettelCheck: true,
      isTeko: false,
    },
    {
      title: 'Chương trình',
      routerLink: '/loyalty/campaigns',
      class: 'iconMenu2',
      nzType: 'appstore',
      nzTheme: 'outline',
      isChildren: false,
      isViettelCheck: true,
      isTeko: false
    },
    {
      title: 'Tài trợ',
      routerLink: '',
      class: 'iconMenu2',
      nzType: 'sketch',
      nzTheme: 'outline',
      isChildren: true,
      isViettelCheck: false,
      children: [
        {
          routerLink: '/loyalty/sponsors/sponsor-me',
          title: 'Gói tài trợ'
        },
        {
          routerLink: '/loyalty/sponsors/sponsor-management',
          title: 'Duyệt tài trợ'
        }
      ]
    },
    // ẩn chương trình sell-out
    // {
    //   title: 'Chương trình Sell-out',
    //   routerLink: '/loyalty/sell-out/campaign',
    //   class: 'iconMenu2',
    //   nzType: 'shopping',
    //   nzTheme: 'outline',
    //   isChildren: true,
    //   children: [
    //     {
    //       routerLink: '/loyalty/sell-out/campaign/create',
    //       title: 'Tạo chương trình'
    //     },
    //     {
    //       routerLink: '/loyalty/sell-out',
    //       title: 'Danh sách'
    //     },
    //     {
    //       routerLink: '2',
    //       title: 'Thống kê chỉ số Sell-in'
    //     },
    //     {
    //       routerLink: '3',
    //       title: 'Thống kê thanh toán'
    //     },
    //     {
    //       routerLink: '4',
    //       title: 'Thống kê điểm thưởng'
    //     },
    //     {
    //       routerLink: '5',
    //       title: 'Lịch sử trả thưởng'
    //     },
    //     {
    //       routerLink: '6',
    //       title: 'Lịch sử đổi điểm'
    //     },
    //   ]
    // },
    {
      title: 'Quản lý gói voucher',
      routerLink: '/loyalty/manager-package-voucher',
      class: 'iconMenu2',
      nzType: 'book',
      nzTheme: 'outline',
      isChildren: true,
      isViettelCheck: true,
      isTeko: false,
      children: [
        {
          routerLink: '/loyalty/voucher-management/vouchers',
          title: 'Danh sách gói voucher',
          isViettelCheck: true
        },
        {
          routerLink: '/loyalty/voucher-management/history-voucher',
          title: 'Lịch sử voucher',
          isViettelCheck: true
        }
      ]
    },
    {
      title: 'Đơn hàng',
      routerLink: '/loyalty/orders',
      class: 'iconMenu2',
      nzType: 'book',
      nzTheme: 'outline',
      isChildren: false,
      isViettelCheck: true,
      isTeko: false,
    },
    {
      title: 'Mã dự thưởng',
      routerLink: '/loyalty/bonus-code',
      class: 'iconMenu2',
      nzType: 'qrcode',
      nzTheme: 'outline',
      isChildren: false,
      isViettelCheck: true,
      isTeko: false,
    },
    {
      title: 'Quà tặng',
      routerLink: '/loyalty/gifts',
      class: 'iconMenu2',
      nzType: 'gift',
      nzTheme: 'outline',
      isChildren: false,
      isViettelCheck: true,
      isTeko: false
    },
    {
      title: 'Khách hàng',
      routerLink: '',
      class: 'iconMenu2',
      nzType: 'team',
      nzTheme: 'outline',
      isChildren: true,
      isViettelCheck: true,
      isTeko: false,
      children: [
        {
          routerLink: '/loyalty/customers/programs',
          title: 'Danh sách khách hàng',
          isViettelCheck: true
        },
        {
          routerLink: '/loyalty/customers/winners',
          title: 'Lịch sử trúng thưởng',
          isViettelCheck: true
        },
        // {
        //   routerLink: '/loyalty/customers/statistical',
        //   title: 'Thống kê trúng thưởng',
        //   isViettelCheck: true
        // },
        {
          routerLink: '/loyalty/customers/activities',
          title: 'Lịch sử tham gia',
          isViettelCheck: true
        }
      ]
    },
    // {
    //   title: 'Tích điểm thành viên',
    //   routerLink: '',
    //   class: 'iconMenu2',
    //   nzType: 'transaction',
    //   nzTheme: 'outline',
    //   isChildren: true,
    //   isViettelCheck: false,
    //   isTeko: true,
    //   children: [
    //     {
    //       routerLink: '/member/member-gift',
    //       title: 'Quà tặng thành viên'
    //     },
    //     {
    //       routerLink: '/member/member-point',
    //       title: 'Điểm khách hàng'
    //     },
    //     {
    //       routerLink: '/loyalty/exchange-gift',
    //       title: 'Danh sách đổi quà'
    //     },
    //     {
    //       routerLink: '/loyalty/exchange-gift-store',
    //       title: 'Thống kê danh sách đổi quà'
    //     },
    //   ]
    // },
    {
      title: 'Điểm tích lũy',
      routerLink: '',
      class: 'iconMenu2',
      nzType: 'transaction',
      nzTheme: 'outline',
      isChildren: true,
      isViettelCheck: true,
      isTeko: false,
      children: [
        {
          routerLink: '/loyalty/accumulate-points/points-customer',
          title: 'Điểm khách hàng',
          isViettelCheck: true
        },
        {
          routerLink: '/loyalty/accumulate-points/gifts-customer',
          title: 'Danh sách đổi quà',
          isViettelCheck: true
        },
        {
          routerLink: '/loyalty/accumulate-points/statistical-gift',
          title: 'Thống kê danh sách đổi quà tại cửa hàng',
          isViettelCheck: true
        }
      ]
    },
    {
      title: 'Cấu hình',
      routerLink: '',
      class: 'iconMenu2',
      nzType: 'setting',
      nzTheme: 'outline',
      isChildren: true,
      isViettelCheck: true,
      isTeko: false,
      children: [
        {
          routerLink: '/loyalty/settings/staffs',
          title: 'Nhân viên',
          isViettelCheck: true
        },
        {
          routerLink: '/loyalty/settings/distributors',
          title: 'Đại lý - Cửa hàng',
          isViettelCheck: false
        }
      ]
    },
  ];
  constructor(
    private router: Router,
    private authService: AuthService,
    private rootService: RootService,
    private notification: NzNotificationService,
    private modalService: NzModalService,
    public commonService: CommonService,
  ) {
    this.getUser();
    // this.checkName = ViettelValue();
  }

  loyaltyUser;
  // tslint:disable-next-line:variable-name
  url_image;
  isCheckAccountVietel: boolean = false;
  dateViettel: any;
  isExpiredViettel: boolean = false;
  ngOnInit() {
    this.$jquery();
    this.listenChange();
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();

    this.subscriptionCampaign = this.commonService.isExpiredByViettel.asObservable().subscribe(val => {
      this.isExpiredViettel = val;
    });


    // check điều kiện tài khoản teko
    // this.rootService.checkCanCreateCampaignLongTermPoints().subscribe(res => {
    //   if (res.statusCode !== 200) {
    //     this.isCheckTeko = true;
    //     console.log('xxxx', this.isCheckTeko);
    //   }
    // });
  }

  changeMenu() {
    this.changeTab = !this.changeTab;
    this.rootService.saveChangeTab(this.changeTab);
    this.listenChange();
  }

  listenChange() {
    if (this.changeTab === true) {
      this.isCollapsed = false;
    } else {
      this.isCollapsed = true;
      this.rootService.currentMessage.pipe(takeUntil(this.unsubscribe$)).subscribe(message => {
        if (this.changeTab === true) {
          this.isCollapsed = false;
          this.menu.map(item => {
            return item.class = !this.isCollapsed ? 'iconMenu' : 'iconMenu2';
          });
        } else {
          this.isCollapsed = message;
          this.menu.map(item => {
            return item.class = !this.isCollapsed ? 'iconMenu' : 'iconMenu2';
          });
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.isCheckAccountVietel) {
      console.log('vào rồi', moment());
      console.log('vào rồi2', moment(this.dateViettel));
      console.log(moment().isAfter(moment(this.dateViettel)));
      if (this.commonService.isExpiredViettel()) {
        const modal = this.modalService.create({
          nzVisible: true,
          nzTitle: `Thông báo`,
          nzContent: DateExpiredComponent,
          nzClosable: false,
          nzWidth: 600,
          nzStyle: { top: '30px' },
          nzFooter: null,
          nzClassName: !this.isCheckAccountVietel ? '' : 'modal-viettel',
          nzMaskClosable: false,
          nzComponentParams: {
          }
        });
      }
    }
  }

  logout() {
    localStorage.clear();
    this.authService.logout();
    this.router.navigate(['/auth/login']);
    // localStorage.removeItem('loyaltyUser');
    // location.reload();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$.unsubscribe();
    this.subscriptionCampaign.unsubscribe();
  }

  $jquery() {
    $('.selected-user').click(function (event) {
      event.stopPropagation();
      $('.icon-down-user').toggleClass('flip');
      $('.box-user').toggle();
    });

    $(window).click(function () {
      if ($('.box-user').css('display') == 'block') {
        $('.box-user').hide();
        $('.icon-down-user').toggleClass('flip');
      }
    });
  }

  updateBusiness() {
    const modal = this.modalService.create({
      nzVisible: true,
      nzTitle: `Thông tin doanh nghiệp`,
      nzContent: UpdateBusinessComponent,
      nzClosable: false,
      nzWidth: 700,
      nzStyle: { top: '30px' },
      nzFooter: null,
      nzClassName: !this.isCheckAccountVietel ? '' : 'modal-viettel',
      nzMaskClosable: false,
      nzComponentParams: {}
    });
    modal.afterClose.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      if (result && result.statusCode === 200) {
        this.notification.create(
          'success',
          `Cập nhật thành công!`,
          `Cập nhật thông tin doanh nghiệp thành công!`
        );
        this.getUser();
      }
      if (result && !result.statusCode) {
        this.notification.create(
          'error',
          'Có lỗi xảy ra !!!',
          `${result}`
        );
      }
    });
  }


  getUser() {
    if (localStorage.getItem('loyaltyUser')) {
      this.loyaltyUser = JSON.parse(localStorage.getItem('loyaltyUser'));
      if (this.loyaltyUser.logo) {
        this.url_image = this.loyaltyUser.logo.small;
      } else {
        this.url_image = '/assets/images/default-avatar.png';
      }
    }
  }

  // popup hiển thị khi hết thời gian sử dụng
  outDateTime() {
    const modal = this.modalService.create({
      nzVisible: true,
      nzTitle: `Thông báo`,
      nzContent: ModalOutDateComponent,
      nzClosable: false,
      nzWidth: 500,
      nzStyle: { top: '30px' },
      nzFooter: null,
      nzClassName: !this.isCheckAccountVietel ? '' : 'modal-viettel',
      nzMaskClosable: false,
      nzComponentParams: {}
    });
  }


}
