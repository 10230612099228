<form nz-form [formGroup]="frmEditBusiness" (ngSubmit)="submitForm()">
    <div class="contentModal">
        <div *ngIf="isCheckAccountVietel" ><p style= "color: #e40521 ">Thời hạn tài khoản: {{expiredAt | date: ' hh:mm:ss dd/MM/yyyy'}} </p></div>

        <nz-form-item>
            <nz-form-label>Logo</nz-form-label>
            <nz-form-control>
                <app-i-image [multiple]=false [maxImage]="1" [fileList]="images" fileType="image/jpeg;image/png;image/jpg"
                    (change)="uploadImageAttribute($event)"></app-i-image>
                <ng-container *ngIf="isImage">
                    <p class="required" style="margin-top: -20px;margin-bottom: -10px;">
                        <i>Vui lòng chọn ảnh</i>
                    </p>
                </ng-container>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Tên doanh nghiệp(<span class="required">*</span>)</nz-form-label>
            <nz-form-control [nzErrorTip]="name">
                <input autocomplete="off" nz-input name="name" type="text" formControlName="name">
                <ng-template #name let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        <p class="required"><i>Vui lòng nhập tên doanh nghiệp</i></p>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Số điện thoại</nz-form-label>
            <nz-form-control [nzErrorTip]="phone">
                <input autocomplete="off" nz-input name="phone" type="text" formControlName="phone">
                <ng-template #phone let-control>
                    <ng-container *ngIf="control.hasError('minlength')">
                        <p class="required"><i>Vui lòng nhập tối đa 11 ký tự</i></p>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">
                        <p class="required"><i>Vui lòng nhập tối thiểu 7 ký tự</i></p>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('pattern')">
                        <p class="required"><i>Số điện thoại phải là số</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Email</nz-form-label>
            <nz-form-control [nzErrorTip]="email">
                <input autocomplete="off" nz-input name="email" type="text" formControlName="email">
                <ng-template #email let-control>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        <p class="required"><i>Chỉ được nhập tối đa 255 ký tự</i></p>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('pattern')">
                        <p class="required"><i>Email không đúng định dạng</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Website</nz-form-label>
            <nz-form-control [nzErrorTip]="website">
                <input autocomplete="off" nz-input name="website" type="text" formControlName="website">
                <ng-template #website let-control>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        <p class="required"><i>Chỉ được nhập tối đa 255 ký tự</i></p>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('pattern')">
                        <p class="required"><i>Website không đúng định dạng</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Tỉnh/thành</nz-form-label>
            <nz-form-control [nzErrorTip]="city_id">
                <nz-select formControlName="city_id" nzAllowClear id="city_id" nzPlaceHolder="Chọn tỉnh/thành"
                    (ngModelChange)="changeCities($event)">
                    <nz-option *ngFor="let o of cities" nzValue="{{o.id}}" nzLabel="{{o.name}}"></nz-option>
                </nz-select>
                <ng-template #city_id let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        <p class="required"><i>Vui lòng chọn tỉnh/thành</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Quận/huyện</nz-form-label>
            <nz-form-control [nzErrorTip]="district_id">
                <nz-select formControlName="district_id" nzAllowClear id="district_id" nzPlaceHolder="Chọn quận/huyện"
                    (ngModelChange)="changeDistricts($event)">
                    <nz-option *ngFor="let o of districts" nzValue="{{o.id}}" nzLabel="{{o.name}}"></nz-option>
                </nz-select>
                <ng-template #district_id let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        <p class="required"><i>Vui lòng chọn quận/huyện</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Phường/xã</nz-form-label>
            <nz-form-control [nzErrorTip]="ward_id">
                <nz-select formControlName="ward_id" nzAllowClear id="ward_id" nzPlaceHolder="Chọn phường/xã"
                    (ngModelChange)="changeWards($event)">
                    <nz-option *ngFor="let o of wards" nzValue="{{o.id}}" nzLabel="{{o.name}}"></nz-option>
                </nz-select>
                <ng-template #ward_id let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        <p class="required"><i>Vui lòng chọn phường/xã</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label>Địa chỉ</nz-form-label>
            <nz-form-control [nzErrorTip]="address">
                <input autocomplete="off" nz-input name="address" type="text" formControlName="address">
                <ng-template #address let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        <p class="required"><i>Vui lòng nhập địa chỉ</i></p>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        <p class="required"><i>Chỉ được nhập tối đa 255 ký tự</i></p>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="!isCheckAccountVietel">
            <nz-form-label>Tài khoản QRcode</nz-form-label>
            <!-- <input nz-input type="text"[disabled]="true" value="{{name_business_qrcode}}"> -->
            <p class="name-business">{{accountQrcodeName}}</p>
        </nz-form-item>
<!--        <nz-form-item>-->
<!--            <nz-form-label>Mã ví chi hộ của doanh nghiệp</nz-form-label>-->
<!--            <input nz-input type="text" formControlName="ent_terminal" />-->
<!--        </nz-form-item>-->

    </div>

    <div class="footerModal">
        <button nz-button [ngClass]="!isCheckAccountVietel ? 'ant-btn-primary' : 'viettel-button'" type="submit">Cập nhật</button>
        <button nz-button nzType="default" type="button" (click)="cancel()" class="btn-df">Bỏ qua</button>
    </div>
</form>