import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { UploadService } from '../../services/upload.service';

const IMG_REGEX = /(.*?)\.(jpg|png|jpeg)$/i;
@Component({
    selector: 'app-ui-upload-image',
    templateUrl: './upload-img.component.html',
    styleUrls: ['./upload-img.component.scss']
})

export class UploadImgComponent implements OnInit, OnDestroy, OnChanges {
    // banners: any = [];

    @Input() banners: any;
    @Input() isUploadImage: any;
    @Input() isUploadBanner: any;
    @Input() isUploadRotation: any;
    @Input() isUploadNeedle: any;
    @Input() isUploadBackground: any;
    @Input() height: any;
    @Input() width: any;
    @Input() isUploadV2: any;
    @Input() required: boolean = false;
    @Output() onReceive = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();

    constructor(private notificationService: NzNotificationService,
        private upload: UploadService) {
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    }


    ngOnInit(): void {
    }


    onChangeBanner(files) {
        if (files?.length > 0) {
            Array.from(files).forEach(file => {
                if (+this.onGetSizeFile((file as any).size) > 5) {
                    this.createNotification('error', 'THÔNG BÁO', 'Dung lượng file vượt quá 5MB');
                } else {
                    if (IMG_REGEX.test((file as any)?.name)) {
                        return this.upload.postImg(file).subscribe((res) => {
                            this.banners = [{
                                src: res.data.url
                            }];
                            this.onReceive.emit({
                                id: res?.data?.url,
                                srcBanner: res?.data.url
                            });
                            this.required = false;

                        });
                    } else {
                        this.createNotification('error', 'THÔNG BÁO', 'Vui lòng chọn định dạng ảnh JPG, PNG, JPEG');
                    }
                }
            });

            // this.banners = images;
        }
    }

    onRemoveImage() {
        this.banners = [];
        this.onDelete.emit({ id: null, isRequired: true });
    }

    onGetSizeFile = (size: string) => (+size / (1024 * 1024)).toFixed(2);

    createNotification(type, title, message) {
        this.notificationService.create(type, title, message);
    }

    ngOnDestroy(): void {
    }

}
