<div class="ui-modal-cover display-none" #uiModal>
    <div #uiModalWindow class="ui-modal-window animated" [ngStyle]="{
      'height': height ? height + 'px' : 'auto',
      'width': fullWidth ? '100%' : (width ? width + 'px' : 'auto'),
      'padding': padding + 'px'
    }">
        <div class="ui-modal-header-wrapper flex" *ngIf="hiddenHeader">
            <div class="ui-modal-title text-center">
                <ng-content select="[ui-modal-title]"></ng-content>
            </div>

            <span class="close mr-4 mt-2 cpointer" (click)="close()">
                <!--        <i class="fas fa-times" aria-hidden="true"></i>-->
                <app-ui-icon name="times" size="xxl" color="black"></app-ui-icon>
            </span>
        </div>

        <div *ngIf="!isScenario" style="position: relative">
            <div>
                <div class="icon-modal">
                    <div class="img-icon-modal">

                    </div>
                </div>
                <img [ngStyle]="{ top: !titleModal ? '23%' : '18%' }" *ngIf="isSuccess" style="position: absolute; left: 47.3%;" src="assets/images/web_app/icon-modal-success.png">
                <img *ngIf="!isSuccess" style="position: absolute;top: 14%;left: 44.5%;width: 56px;height: 56px;" src="assets/images/campaign-v2/fail-icon.png">
                <img *ngIf="isWaring" style="position: absolute;top: 14%;left: 45.5%;width: 56px;height: 56px;" src="assets/images/campaign-v2/fail-icon.png">
                <img *ngIf="!isCheckAccountVietel" [ngStyle]="{
                 height: !titleModal ? '120px' : '',
                 width: !titleModal ? '100%' : ''
            }" src="./assets/images/background-modal.png">
                <!-- viettelCheck -->
                <img *ngIf="isCheckAccountVietel" [ngStyle]="{
                 height: !titleModal ? '86px' : '146px',
                 width: !titleModal ? '100%' : '630px'
            }" src="./assets/images/background-modal-viettel.png">

                <div [ngStyle]="{
                    top: isSuccess ? '70%' : '50%'
                }" class="text-center title-modal-popup-success" *ngIf="titleModal">{{titleModal}}</div>
            </div>
        </div>

        <div *ngIf="isScenario" style="position: relative">
            <img [ngStyle]="{  top: !titleModal ? '23%' : '18%' }" *ngIf="isSuccess" style="position: absolute; left: 47.3%;" src="assets/images/web_app/icon-modal-success.png">
            <img *ngIf="!isSuccess" style="position: absolute;top: 16%;left: 42.7%;width: 56px;height: 56px;" src="assets/images/campaign-v2/fail-icon.png">
<!--            <img *ngIf="!isCheckAccountVietel" [ngStyle]="{ height: !titleModal ? '86px' : '', width: !titleModal ? '100%' : ''}" src="./assets/images/background-scenario.png">-->
            <img *ngIf="!isCheckAccountVietel" [ngStyle]="{ height: !titleModal ? '86px' : '', width: '100%'}" src="./assets/images/background-scenario.png">
            <div [ngStyle]="{ top: '50%' }" class="text-center maxLengths title-modal-popup-success" *ngIf="titleModal">{{titleModal}}</div>
        </div>

        <!--        <div class="ui-modal-header-wrapper flex" *ngIf="!hiddenHeader">-->
        <!--            <div class="ui-modal-title text-center">-->
        <!--                <ng-content select="[ui-modal-title]"></ng-content>-->
        <!--            </div>-->

        <!--            &lt;!&ndash;      <span class="close" (click)="close()">&ndash;&gt;-->
        <!--            &lt;!&ndash;        <i class="fas fa-times" aria-hidden="true"></i>&ndash;&gt;-->
        <!--            &lt;!&ndash;      </span>&ndash;&gt;-->
        <!--        </div>-->

        <div class="ui-modal-content">
            <ng-content select="[ui-modal-body]"></ng-content>
        </div>

        <div>
            <div class="ui-modal-footer" *ngIf="!hiddenFooter">
                <ng-content select="[ui-modal-footer]"></ng-content>
            </div>
        </div>
    </div>
</div>
