import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';

@Injectable({
    providedIn: 'root'
})

export class CommonService {
    loyaltyUser: any;
    date: any;
    isExpiredByViettel = new BehaviorSubject(false);

    changeExpiredViettel(status) {
        this.isExpiredByViettel.next(status);
    }
    constructor() {
        this.loyaltyUser = JSON.parse(localStorage.getItem('loyaltyUser'));
    }

    isCheckAccountByViettel() {
        if (this.loyaltyUser) {
            // .toLowerCase()
            return this.loyaltyUser.special_partner_code === 'VIETTEL';
        }
        return false;
    }

    isExpiredViettel(date?: any) {
        return moment(date ? date : '').isAfter(moment(this.loyaltyUser.expired_at));
    }

    copyText(val) {
        const textarea = document.createElement('textarea');
        textarea.style.position = 'fixed';
        textarea.style.left = '0';
        textarea.style.top = '0';
        textarea.style.opacity = '0';
        textarea.value = val;
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    }
}
