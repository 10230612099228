import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzNotificationService, NzModalService } from 'ng-zorro-antd';
import { GiftSharedService, CrudCodeService } from '../../services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GIFT, ICOIN, CAMPAIGN, CARD, CONFIG } from '../../models/constant';
import { CheckNumberService, NotificationService } from 'src/app/lib';
import { ListComponentComponent } from '../list-component/list-component.component';
import { CommonService } from '../../services/common/common.service';
import { track } from '../../../helpers/tracking-teko';
import {GoogleAnalyticService} from "../../../modules/loyalty/google-analytic-service/google-analytic.service";
import {AlertRetargetComponent} from "../alert-retarget/alert-retarget.component";
import {ModalAlertComponent} from "../modal-alert/modal-alert.component";

@Component({
  selector: 'app-gift-add',
  templateUrl: './gift-add.component.html',
  styleUrls: ['./gift-add.component.scss']
})
export class GiftAddComponent extends ListComponentComponent implements OnInit, OnDestroy {
  isCheckAccountVietel = false;
  constructor(
    private formBuilder: FormBuilder,
    public modalRef: NzModalRef<GiftAddComponent>,
    private giftSharedService: GiftSharedService,
    private checkNumberService: CheckNumberService,
    public modalService: NzModalService,
    public notification: NzNotificationService,
    private notiService: NotificationService,
    public commonService: CommonService,
    public router: Router,
    public crudCodeService: CrudCodeService,
    private googleAnalyticService: GoogleAnalyticService,
  ) {
    super(modalService, notification, router, crudCodeService, commonService);
    this.createForm();
    this.googleAnalyticService.initialize();
  }
  // config summnernote
  public config = CONFIG.config_editor;

  @Input() gift;
  @Input() type;
  subscriptions: Subscription = new Subscription();
  tooltipiCoin = CAMPAIGN.tooltip.icoin_product;
  typeForm;
  frmAddGift: FormGroup;
  typeGifts = GIFT.type;
  valueOptions = [];
  images = [];
  isImage = false;

  descriptionProgram = {
    placeholder: 'Mô tả quà',
    width: '1000px',
    value: null,
    size: 6
  };

  valImageVNPoint = {
    10: 'img_079NFpcp4k6CgAOCfTnyd',
    20: 'img_rb2Zzuf3YTtxLN9ui0mh9',
    50: 'img_gWESrNOSwx4vZdN2bNTii',
    100 : 'img_kGvkNsvJB7BZcy7PDEKr7',
    200: 'img_i2-gMbQppfwtYbcAeLjsS',
    500: 'img_PGl45fhp8YlCBnZ8jrlYr',
    1000: 'img_1XIyuzvTPZaqMvsvtvPan',
    2000: 'img_EvH5V4TMPCBioixvbdLw0',
    5000: 'img_PQvWkTYzfo03ms4hjQsKN',
    10000: 'img_7LnRhYNCYE9tYZtMPKWCA',
    20000: 'img_LO5HaJDCx4Kl21EO5hdTn',
    50000: 'img_KmuzEIigkmPoP71xfEo3C',
    100000: 'img_wS72u17Hi-8-2C9zYDxpi',
    200000: 'img_cNnIGUGLeJQccHblh5Z5Y',
    500000: 'img_Tv-LwcjJ8vx32jsucSktU'
  };

  errMessage = '';
  imgVNPOINT: any;

  ngOnInit() {
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();
    /*Neu this.gift co gia tri thi action la UPDATE nguoc lai la INSERT*/
    if (this.gift) {
      this.updateForm();
      this.checkTooltip();
    } else {
      this.createForm();
    }

    this.frmAddGift.get('type').valueChanges.subscribe(val => {
      if (val === 'VNPAY_QR' || val === 'CONGRATULATION') {
        this.frmAddGift.controls.icoin.clearValidators();
      } else {
        this.frmAddGift.controls.icoin.setValidators([Validators.required]);
      }
    });

    this.frmAddGift.get('icoin').valueChanges.subscribe(val => {
      if (val && this.frmAddGift.get('type').value === 'ICOIN') {
        this.imgVNPOINT = `assets/images/vn-point/${val}.png`;
        console.log("val=====>",val);
        
        this.frmAddGift.get('image').setValue(this.valImageVNPoint[val]);
      } else {
        if (!this.gift && this.frmAddGift.get('type').value === 'ICOIN') {
          this.imgVNPOINT = null;
          this.frmAddGift.get('image').setValue(null);
        }
      }
    });
  }
  checkTooltip() {
    this.frmAddGift.controls.icoin.setValue(null);
    if (this.frmAddGift.value.type === 'PRODUCT') {
      this.tooltipiCoin = CAMPAIGN.tooltip.icoin_product;
    } else if (this.frmAddGift.value.type === 'ICOIN') {
      this.valueOptions = ICOIN;
      this.tooltipiCoin = CAMPAIGN.tooltip.icoin_icoin;
    } else if (this.frmAddGift.value.type === 'PHONE_CARD') {
      this.valueOptions = CARD;
      this.tooltipiCoin = CAMPAIGN.tooltip.phone_card;
    } else if (this.frmAddGift.value.type === 'DIGITAL_MONEY') {
      this.valueOptions = CARD;
      this.tooltipiCoin = CAMPAIGN.tooltip.digital_money;
    }
  }

  createForm() {
    this.typeForm = 'Thêm mới';
    this.createFormGift();
  }

  updateForm() {
    this.images = [{
      uid: 1,
      name: '',
      status: 'done',
      url: this.gift.image.small,
      thumbUrl: this.gift.image.small,
      id: this.gift.image.id
    }];
    this.typeForm = 'Cập nhật';
    this.typeGifts = GIFT.type_update;
    this.createFormGift().then(() => {
      this.frmAddGift.patchValue(this.gift);
      // this.frmAddGift.get('name').patchValue(this.gift.name);
      this.frmAddGift.get('image').patchValue(this.gift.image.id);
      // this.frmAddGift.get('type').patchValue(this.gift.type);
      this.frmAddGift.get('icoin').patchValue(this.gift.icoin || 0);
      this.frmAddGift.get('weight').patchValue(this.gift?.weight || 0);
      // this.frmAddGift.get('description').patchValue(this.gift.description);
      // this.frmAddGift.get('active').patchValue(this.gift.active);
      // this.frmAddGift.get('manual_release').patchValue(this.gift.manual_release);
      // if (this.frmAddGift.value.type === 'VNPAY_QR') {
      //   this.frmAddGift.get('vnpay_campaign_id').patchValue(this.gift.gift_vnpay.campaign_id);
      //   this.frmAddGift.get('vnpay_gift_code').patchValue(this.gift.gift_vnpay.gift_code);
      // }
    });
    console.log("this.gift.icoin========>",this.gift.icoin);
    
  }

  createFormGift() {
    return new Promise((resolve, reject) => {
      this.frmAddGift = this.formBuilder.group({
        name: [null, [
          Validators.required,
          Validators.maxLength(255)
        ]],
        image: [null, [Validators.required]],
        type: ['PRODUCT', [
          Validators.required
        ]],
        icoin: [0, [Validators.required]],
        description: [null],
        active: [true],
        manual_release: [false],
        vnpay_campaign_id: [null],
        vnpay_gift_code: [null],
        transport: [false],
        weight: [null],
      });
      resolve();
    });
  }

  uploadImageAttribute($event) {
    console.log('$event', $event);
    if ($event.fileList) {
      if ($event.type === 'removed') {
        this.frmAddGift.controls.image.setValue(null);
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].id === $event.file.id) {
            this.images.splice(i, 1);
            this.frmAddGift.controls.image.setValue(null);
          }
        }
      }
      if ($event.type === 'success' && $event.file.status === 'done') {
        this.frmAddGift.controls.image.setValue($event.file.response.id);
        this.isImage = false;
      }
    }
  }

  changeTypeGift(event) {
    this.checkTooltip();
    if (event === 'CONGRATULATION' || event === 'RECEIVE_STORE') {
      this.frmAddGift.controls.icoin.setValue(0);
    }
    //
    // if (event === 'ICOIN') {
    //   this.frmAddGift.get('image').setValue('img_L9KylNhL1k0MKCVh0elak');
    // }
    // if (event === 'PRODUCT') {
    //   this.frmAddGift.get('weight').setValidators([Validators.required, Validators.min(0)]);
    // } else {
    //   this.frmAddGift.get('weight').clearValidators();
    // }
    // this.frmAddGift.get('weight').updateValueAndValidity();
  }

  onConfigLogistic() {
    if (this.frmAddGift.get('transport').value) {
      this.giftSharedService.getTransportConfig().subscribe(res => {
        if (res && res.statusCode === 200) {
          if (!res?.data?.check) {
            this.frmAddGift.get('transport').setValue(false);
            const modal = this.modalService.create({
              nzVisible: true,
              nzTitle: `Thông báo`,
              nzContent: ModalAlertComponent,
              nzClosable: false,
              nzWidth: 500,
              nzFooter: null,
              nzMaskClosable: false,
              nzComponentParams: {
                isClose: false,
                title: 'Bạn chưa đăng ký sử dụng dịch vụ Logistic cho quà hiện vật',
                content: 'Vui lòng liên hệ với iCheck để đăng ký sử dụng \n' +
                    'dịch vụ này'
              }
            });
            modal.afterClose.subscribe(data => {
                // console.log("data", data)
            });
            // this.notification.error('Thông báo', res?.message);
          }
        }
      });
    }
    if (this.frmAddGift.get('transport').value) {
      this.frmAddGift.get('weight').setValidators([Validators.required, Validators.min(0)]);
    } else {
      this.frmAddGift.get('weight').clearValidators();
    }
    this.frmAddGift.get('weight').updateValueAndValidity();
  }

  submitForm() {
    let checkSpace = false;

    /*validate image gift*/
    if (this.frmAddGift.value.image) {
      this.isImage = false;
    } else { this.isImage = true; }

    for (const key of Object.keys(this.frmAddGift.controls)) {
      this.frmAddGift.controls[key].markAsDirty();
      this.frmAddGift.controls[key].updateValueAndValidity();
    }

    if (this.frmAddGift.value.name && this.frmAddGift.value.name.replace(/\s/g, '') === '') {
      checkSpace = true;
      this.frmAddGift.controls.name.setValue(null);
    }

    if (this.frmAddGift.value.type === 'VNPAY_QR') {
      if (this.frmAddGift.get('vnpay_campaign_id').value == null || this.frmAddGift.get('vnpay_gift_code').value == null
        || this.frmAddGift.get('vnpay_campaign_id').value == '' || this.frmAddGift.get('vnpay_gift_code').value == '') {
        this.notiService.notiError(`Vui lòng điền đầy đủ thông tin cần thiết.`);
        return;
      }
    }

    if (this.frmAddGift.valid && !checkSpace) {
      if (this.gift) {// update

        // tracking
        // track('manualTrackInteractionContent', {
        //   contentName: 'Sua',
        //   interaction: 'click',
        //   regionName: 'createGiftPopup',
        //   target: 'giftList'
        // });
        // end
        const endpoint = `/gift/${this.gift.id}`;
        if (this.frmAddGift.value.icoin === '' || this.frmAddGift.value.icoin === undefined) {
          this.frmAddGift.controls.icoin.setValue(null);
        }

        const payload = {...this.frmAddGift.value};
        payload.weight = this.frmAddGift.get('weight').value || 0;
        this.putData(endpoint, payload).then(res => {
          track('alert', {
            alertType: res ? 'success' : 'failed',
            alertMessage: res ? `${this.type === 'updateGift' ? 'Cap nhat' : 'Tao moi'} qua tang thanh cong` : `${this.type === 'updateGift' ? 'Cap nhat' : 'Tao moi'} qua tang that bai`
          });
          if (res) {
            this.giftSharedService.addDone(true);
            this.frmAddGift.reset();
            this.modalRef.close(res);
          }
        });
      } else {// add

        // tracking
        // track('manualTrackInteractionContent', {
        //   contentName: 'Them',
        //   interaction: 'click',
        //   regionName: 'createGiftPopup',
        //   target: 'giftList'
        // });
        // end
        const endpoint = `/gift`;
        const payload = {...this.frmAddGift.value};
        payload.weight = this.frmAddGift.get('weight').value || 0;
        this.postData(endpoint, payload).then(res => {
          // track('alert', {
          //   alertType: res ? 'success' : 'failed',
          //   alertMessage: res ? `${this.type === 'updateGift' ? 'Cap nhat' : 'Tao moi'} qua tang thanh cong` : `${this.type === 'updateGift' ? 'Cap nhat' : 'Tao moi'} qua tang that bai`
          // });
          try {
            this.googleAnalyticService.event('create_gift', {
              gift_name: this.frmAddGift.get('name')?.value,
              gift_type: this.frmAddGift.get('type')?.value,
              gift_value: this.frmAddGift.get('icoin')?.value,
            });
          } catch (e) {
            console.log(e);
          }
          if (res) {
            this.giftSharedService.addDone(true);
            this.frmAddGift.reset();
            this.modalRef.close(res);
          }
        });
      }
    }
  }

  formatNumber($event, key) {
    // tslint:disable-next-line:radix
    this.frmAddGift.get(key).setValue(Math.abs($event.target.value));
  }

  checkNumber($event) {
    const isNumber = this.checkNumberService.checkNumberInput($event.target.value);
    if (isNumber.status) {
      this.frmAddGift.get('icoin').patchValue($event.target.value);
    } else {
      this.errMessage = isNumber.message;
    }
    
  }

  cancel() {
    if (this.typeForm === 'Cập nhật') {
      // tracking
      track('manualTrackInteractionContent', {
        contentName: 'Bo qua',
        interaction: 'click',
        regionName: 'createGiftPopup',
        target: 'giftList'
      });
      // end
    } else {
      // tracking
      track('manualTrackInteractionContent', {
        contentName: 'Bo qua',
        interaction: 'click',
        regionName: 'updateGiftPopup',
        target: 'giftList'
      });
      // end
    }
    this.subscriptions.unsubscribe();
    this.modalRef.close();
  }

  // viettelCheck
  hiddenOptionViettel(option) {
    if (this.isCheckAccountVietel) {
      return option.isViettelCheck;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
