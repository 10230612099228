import { Injectable } from '@angular/core';
import { BaseLoyaltyService } from './base-loyalty.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CrudCodeService extends BaseLoyaltyService {

    getAnyThing(endpoint: string, query?: any): Observable<any> {
        return this.get<any>(`${endpoint}`, { params: query });
    }

    deletes(endpoint: string, body: any): Observable<any> {
        return this.delete<any>(`${endpoint}`, body);
    }

    postAnyThing(endpoint: string, body: any, query?: any): Observable<any> {
        return this.post<any>(`${endpoint}`, body, { params: query });
    }

    putAnyThing(endpoint: string, body: any): Observable<any> {
        return this.put<any>(`${endpoint}`, body);
    }

    patchAnyThing(endpoint: string, body: any): Observable<any> {
        return this.patch<any>(`${endpoint}`, body);
    }

    getSettingRedirect(idcampaign) {
        return this.get<any>(`campaign/${idcampaign}/setting-redirect`)
    }

    postSettingRedirect(idCampaign, body) {
        return this.post<any>(`campaign/${idCampaign}/setting-redirect`, body)
    }

    PostExcel(endpoint: string, body: any, query): Observable<any> {
        return this.post<any>(`${endpoint}`,body, {params: query});
    }
}
