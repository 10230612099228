<!--<app-ui-modal [width]="460" [(show)]="isShow" class="ui-modal-submit" [titleModal]="'Thông báo'">-->
<!--    <div ui-modal-body>-->
<!--        <div class="ui-modal-common-campaign" style="padding: 0 20px">-->
<!--            <div class="txt-center" style="font-weight: bold; margin: 25px 0 5px;">Bạn chưa đăng ký sử dụng dịch vụ Re-target</div>-->

<!--            <div class="txt-center">Vui lòng liên hệ iCheck để đăng ký sử dụng dịch vụ này</div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div ui-modal-footer>-->
<!--        <div class="d-flex" style="justify-content: center; padding: 30px 50px 35px">-->
<!--            <button class="btn-got-it" nz-button nzType="default" (click)="closeModal()">Đã hiểu</button>-->
<!--        </div>-->
<!--    </div>-->
<!--</app-ui-modal>-->

<div class="ui-modal-common-campaign" style="padding: 0 20px">
    <div class="txt-center" style="font-weight: bold; margin: 25px 0 5px;">{{title}}</div>

    <div class="txt-center">{{content}}</div>
</div>

<div class="d-flex" style="justify-content: center; padding: 30px 50px 20px; gap: 10px">
    <button nz-button [ngClass]="!isCheckAccountVietel ? 'ant-btn-primary' : 'viettel-button'" nzType="default" (click)="closeModal('confirm')">{{nameConfirm}}</button>
    <button *ngIf="isClose" class="btn-close" nz-button nzType="default" (click)="closeModal('cancel')">{{nameClose}}</button>
</div>
