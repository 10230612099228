import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Directive({
  selector: '[appDragDrop]'
})
// tslint:disable-next-line:directive-class-suffix
export class DragDropDirectiveShare {

  constructor(private sanitizer: DomSanitizer) { }

  @Output() files: EventEmitter<any> = new EventEmitter();

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    // for (let i = 0; i < evt.dataTransfer.files.length; i++) {
    //   const file = evt.dataTransfer.files[i];
    //   const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    //   files.push({ file, url });
    // }
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.files.emit(files);
    }
  }

}
