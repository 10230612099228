<form nz-form [formGroup]="frmAddBonusCode" (ngSubmit)="submitForm()">
    <div class="contentModal">
      <nz-form-item>
        <nz-form-label>Lịch sử tham gia</nz-form-label>
        <nz-form-control [nzErrorTip]="name">
          <input nz-input autocomplete="off" name="activities" (blur)="checkInput($event)" formControlName="activities" type="text" 
          placeholder="Nhập link file google sheet nhận dữ liệu báo Lịch sử tham gia">
          <ng-template #name let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>Vui lòng nhập link lịch sử tham gia</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">
              <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    
      <nz-form-item>
        <nz-form-label>Lịch sử trúng thưởng</nz-form-label>
        <nz-form-control [nzErrorTip]="quota">
          <input nz-input autocomplete="off" name="winners" (blur)="checkInput($event)" formControlName="winners" type="text" 
          placeholder="Nhập link file google sheet nhận dữ liệu báo Lịch sử trúng thưởng">
          <ng-template #quota let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>Vui lòng nhập link lịch sử trúng thưởng</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">
              <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div>Sau khi điền link file google sheet, vui lòng thêm quyền edit cho tài khoản:
          <span nz-tooltip [nzTooltipTitle]="Index === 1 ? 'Đã sao chép' : 'Sao chép'" (click)="copyLanding(1)" style="color:#e2541b; cursor: pointer;">loyalty-bot@icheck-loyalty-dev.iam.gserviceaccount.com </span>
          để cho phép hệ thống đẩy dữ liệu báo cáo lên file của bạn.
      </div>
    </div>
    <div class="footerModal">
      <button nz-button class="ant-btn-primary"  type="submit">Lưu</button>
      <button nz-button nzType="default" (click)="closeModal()" class="btn-df">Huỷ</button>
    </div>
  </form>