<div *ngIf="!isUploadV2 && !isUploadImage && !isUploadNeedle && !isUploadRotation && !isUploadBanner && !isUploadBackground">
    <div class="upload" [ngStyle]="{
        width: width ? width : '100%',
        height: height ? height : '267px'
    }">
        <img appDragDrop [ngStyle]="{
            width: width ? width : '100%'
        }" [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload2.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')',
            height: height ? '169px' : '262px'
            }"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>

<div *ngIf="isUploadV2">
    <div class="upload-v2">
        <img appDragDrop (files)="onChangeBanner($event)" [hidden]="banners?.length" (click)="banner.click()" src="./assets/images/upload.svg">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">

        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
</div>



<div *ngIf="isUploadImage">
    <div class="upload">
        <img appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-background.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" style="width:130px; height: 220px;" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')'}"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>


<div *ngIf="isUploadBanner">
    <div class="upload" style="width: 135px">
        <img width="135px" appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-banner.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" style="height: 80px; width: 130px;" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')'}"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>

<div *ngIf="isUploadRotation">
    <div class="upload">
        <img appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-rotation.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success">
            <div style="width:120px" class="img-success" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')',
            height: height ? '120px' : '262px'
            }"></div>
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>
<div *ngIf="isUploadNeedle">
    <div class="upload">
        <img appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-needle.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div style="width:120px" class="img-success" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')',
            height: height ? '120px' : '262px'
            }"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>
<div *ngIf="isUploadBackground">
    <div class="upload">
        <img appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/uploadBackground.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div style="width:120px" class="img-success" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')',
            height: height ? '120px' : '262px'
            }"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>
