import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import { RootService } from '../../services/root.service';
import { BusinessService } from '../../services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Business } from '../../models/business';
import { CommonService } from '../../services/common/common.service';


@Component({
  selector: 'app-update-business',
  templateUrl: './update-business.component.html',
  styleUrls: ['./update-business.component.scss']
})
export class UpdateBusinessComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: NzModalRef<UpdateBusinessComponent>,
    public rootService: RootService,
    private businessService: BusinessService,
    private notification: NzNotificationService,
    private business: Business,
    public commonService: CommonService
  ) {
    this.business = JSON.parse(localStorage.getItem('loyaltyUser'));
    this.createForm();
  }
  frmEditBusiness: FormGroup;
  urlImage;
  cities;
  districts;
  wards;
  images = [];
  isImage = false;
  accountQrcodeName;
  cityId;
  districId;
  wardId;
  // tslint:disable-next-line:variable-name
  expiredAt: any;
  isCheckAccountVietel = false;

  ngOnInit() {
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();

    this.expiredAt = this.business.expired_at;
    this.listCities();
    if (this.business.logo && this.business.logo.id) {
      this.urlImage = this.business.logo.small;
    }
    if (this.business.logo) {
      this.images = [{
        uid: 1,
        name: '',
        status: 'done',
        url: this.urlImage ? this.urlImage : null,
        thumbUrl: this.urlImage ? this.urlImage : null,
        id: this.business.logo
      }];
    }

    if (this.business.city_id) {
      this.listDistricts(this.business.city_id);
    }

    if (this.business.district_id) {
      this.listWards(this.business.district_id);
    }

    if (this.business.account_qrcode_id !== null) {
      this.accountQrcodeName = this.business.account_qrcode_name;
    } else {
      this.accountQrcodeName = 'Chưa liên kết';
    }
  }

  createForm() {
    this.frmEditBusiness = this.formBuilder.group({
      logo: [this.business.logo ? this.business.logo.id : null, [
      ]],
      name: [this.business.name, [
        Validators.required,
        Validators.maxLength(255)
      ]],
      phone: [this.business.phone, [
        Validators.minLength(7),
        Validators.maxLength(11),
        Validators.pattern('[0-9]*')
      ]],
      email: [this.business.email, [
        Validators.maxLength(255),
        Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')
      ]],
      website: [this.business.website, [
        Validators.maxLength(255)
      ]],
      city_id: [this.business.city_id ? String(this.business.city_id) : null, []],
      district_id: [this.business.district_id ? String(this.business.district_id) : null, []],
      ward_id: [this.business.ward_id ? String(this.business.ward_id) : null, []],
      address: [this.business.address, [
        Validators.maxLength(255)
      ]],
      // ent_terminal: [this.business.ent_terminal ? String(this.business.ent_terminal) : null, []]
    });
  }

  uploadImageAttribute($event) {
    if ($event.fileList) {
      if ($event.type === 'removed') {
        for ( let i = 0; i < this.images.length; i++) {
          if ( this.images[i].id === $event.file.id) {
            this.images.splice(i, 1);
            this.frmEditBusiness.controls.logo.setValue(null);
          }
        }
      }
      if ($event.type === 'success' && $event.file.status === 'done') {
        this.frmEditBusiness.controls.logo.setValue($event.file.response.id);
        this.isImage = false;
      }
    }
  }

  listCities() {
    this.rootService.getCities().subscribe(res => {
      this.cities = res.rows;
    });
  }
  changeCities(event) {
    this.cityId = parseInt(event);
    if (this.cityId) {
      this.listDistricts(this.cityId);
      this.frmEditBusiness.controls.district_id.setValue(null);
      this.frmEditBusiness.controls.ward_id.setValue(null);
    }
  }

  listDistricts(cityId) {
    this.rootService.getDistricts(cityId).subscribe(res => {
      this.districts = res.rows;
    });
  }
  changeDistricts(event) {
    this.districId = parseInt(event);
    if (this.districId) {
      this.listWards(this.districId);
      this.frmEditBusiness.controls.ward_id.setValue(null);
    }
  }
  changeWards(event) {
    this.wardId = parseInt(event);
  }

  listWards(districId) {
    this.rootService.getWards(districId).subscribe(res => {
      this.wards = res.rows;
    });
  }

  submitForm() {
    let checkSpace = false;
    for (const key of Object.keys(this.frmEditBusiness.controls)) {
      this.frmEditBusiness.controls[key].markAsDirty();
      this.frmEditBusiness.controls[key].updateValueAndValidity();
    }

    if (this.frmEditBusiness.value.city_id === null) {
      this.frmEditBusiness.controls.district_id.setValue(null);
      this.frmEditBusiness.controls.ward_id.setValue(null);
    }

    if (this.frmEditBusiness.value.district_id === null) {
      this.frmEditBusiness.controls.ward_id.setValue(null);
    }

    if (this.frmEditBusiness.value.name.replace(/\s/g, '') === '') {
      checkSpace = true;
      this.frmEditBusiness.controls.name.setValue(null);
    }

    if (this.frmEditBusiness.valid && !checkSpace) {
      const body = {
        name: this.frmEditBusiness.value.name.trim(),
        logo: this.frmEditBusiness.value.logo,
        email: this.frmEditBusiness.value.email,
        phone: this.frmEditBusiness.value.phone,
        address: this.frmEditBusiness.value.address,
        website: this.frmEditBusiness.value.website,
        // email: this.frmEditBusiness.value.email.trim(),
        // phone: this.frmEditBusiness.value.phone.trim(),
        // address: this.frmEditBusiness.value.address.trim(),
        // website: this.frmEditBusiness.value.website.trim(),
        city_id: this.frmEditBusiness.value.city_id,
        district_id: this.frmEditBusiness.value.district_id,
        ward_id: this.frmEditBusiness.value.ward_id,
        expired_at: this.frmEditBusiness.value.expired_at,
        // ent_terminal: this.frmEditBusiness.value.ent_terminal
      };
      this.businessService.updateBusiness(body).subscribe(
        (res) => {
          if (res.statusCode === 200) {
            this.notification.create(
              'success',
              `Cập nhật thành công`,
              `Cập nhật thông tin doanh nghiệp thành công!`
            );
            localStorage.setItem('loyaltyUser', JSON.stringify(res.data));
            this.modalRef.close();
            if ((res.data.name !== this.business.name) || (res.data.logo.id !== this.business.logo.id)) {
              setTimeout(() => {
                location.reload();
              }, 2000);
            }
          } else {
            this.notification.create(
              'error',
              'Có lỗi xảy ra !!!',
              `${res.message}.`
            );
          }
        },
        (error) => {
          this.notification.create(
            'error',
            'Có lỗi xảy ra !!!',
            `${error}.`
          );
        }
      );
    }
  }

  cancel() {
    this.modalRef.close();
  }
}
