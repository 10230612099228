import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd";
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {
  isShow = false;
  @Input() nameConfirm = 'Đã hiểu';
  @Input() nameClose = 'Hủy bỏ';
  @Input() title = '';
  @Input() content = '';
  @Input() isClose = true;
  isCheckAccountVietel = false
  constructor(
      private modalRef: NzModalRef<ModalAlertComponent>,
      private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();
  }

  onShowModal() {
    this.isShow = true;
  }

  closeModal(type: string) {
    // this.isShow = false;
    this.modalRef.close(type);
  }

}
