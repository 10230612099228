import { WarningNotiComponent } from './components/warning-noti/warning-noti.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxSummernoteModule } from 'node_modules/ngx-summernote';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { GiftAddComponent } from './components/gift-add/gift-add.component';
import { ImageComponent } from './components/core/image/image.component';
import { SelectboxComponent } from './components/core/selectbox/selectbox.component';
import { InputComponent } from './components/core/input/input.component';
import { UploaderComponent } from './components/core/uploader';
import { UploadFileComponent } from './components/core/upload-file';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';

// Import service
import {
	GiftSharedService, SyncService, CampaignSharedService,
	WinnersService, BusinessService, StaffsService, CrudCodeService
} from './services';
import { DeletePopupComponent } from './components/core/delete-popup/delete-popup.component';
import { UpdateBusinessComponent } from './components/update-business/update-business.component';
import { Business } from './models/business';
import { StaffAddComponent } from './components/staff-add/staff-add.component';
import { StaffPermissionComponent } from './components/staff-permission/staff-permission.component';
import { DistributorAddComponent } from './components/distributor-add/distributor-add.component';

import { CheckNumberService, NotificationService, ValidatorService } from '../lib';
import { ListComponentComponent } from './components/list-component/list-component.component';
import { WarningPermissionComponent } from './components/warning-permission/warning-permission.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { ExportShareComponent } from './components/exports/export.component';
import { WarningLeaveComponent } from './components/warning-leave/warning-leave.component';
import {UIModalComponent} from './components/modal/modal.component';
import { ModalOutDateComponent } from './modal-out-date/modal-out-date.component';
import { DateExpiredComponent } from './date-expired/date-expired.component';
import {AppInputNumberDirective} from "./directive/input-number";
import { UploadImgComponent } from './components/upload-img/upload-img.component';
import {LoyaltyModule} from '../modules/loyalty/loyalty.module';
import {DragDropDirectiveShare} from './directive/drag-drop.directive';
import { ExportFileComponent } from './components/export-file/export-file.component';
import { UploadImgButtonComponent } from './components/upload-img-button/upload-img-button.component';
import { AlertRetargetComponent } from './components/alert-retarget/alert-retarget.component';
import { ModalAlertComponent } from './components/modal-alert/modal-alert.component';
const SERVICE_LIB = [CheckNumberService, NotificationService, ValidatorService];

@NgModule({
	declarations: [
		ExportShareComponent,
		GiftAddComponent,
		ImageComponent,
		SelectboxComponent,
		InputComponent,
		UploaderComponent,
		UploadFileComponent,
		DeletePopupComponent,
		UpdateBusinessComponent,
		StaffAddComponent,
		StaffPermissionComponent,
		DistributorAddComponent,
		ListComponentComponent,
		WarningPermissionComponent,
		FilterBarComponent,
		UIModalComponent,
		ModalOutDateComponent,
		WarningLeaveComponent,
		WarningNotiComponent,
		ModalOutDateComponent,
		DateExpiredComponent,
		AppInputNumberDirective,
		UploadImgComponent,
		DragDropDirectiveShare,
		ExportFileComponent,
		UploadImgButtonComponent,
		AlertRetargetComponent,
		ModalAlertComponent
	],
    imports: [
        CommonModule,
        NgZorroAntdModule,
        FormsModule,
        ReactiveFormsModule,
        NzUploadModule,
        NzSelectModule,
        NgxSummernoteModule,
        NzBreadCrumbModule,
    ],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [
		ExportShareComponent,
		GiftAddComponent,
		ImageComponent,
		UploadFileComponent,
		DeletePopupComponent,
		UpdateBusinessComponent,
		StaffAddComponent,
		UIModalComponent,
		ModalOutDateComponent,
		StaffPermissionComponent,
		DistributorAddComponent,
		WarningPermissionComponent,
		UploadImgComponent,
		UploadImgButtonComponent,
		FilterBarComponent,
		AppInputNumberDirective,
		DragDropDirectiveShare,
		AlertRetargetComponent,
		AlertRetargetComponent
	],
	entryComponents: [
		ExportShareComponent,
		GiftAddComponent,
		UpdateBusinessComponent,
		StaffAddComponent,
		UIModalComponent,
		ModalOutDateComponent,
		StaffPermissionComponent,
		DistributorAddComponent,
		WarningPermissionComponent,
		ModalAlertComponent
	],
	providers: [
		...SERVICE_LIB,
		SyncService,
		CampaignSharedService,
		GiftSharedService,
		WinnersService,
		BusinessService,
		StaffsService,
		Business,
		CrudCodeService
	]
})
export class SharedModule { }
