import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLoyaltyService } from 'src/app/shared/services/base-loyalty.service';
import { ResponseModel } from 'src/app/shared/models/response';
import { map } from 'rxjs/operators';

@Injectable()
export class SharesService extends BaseLoyaltyService {

  getGame(): Observable<any> {
    return this.get<any>(`/sponsor/game`);
  }

  // theme
  getThemesGame(): Observable<any> {
    return this.get<any>(`/sponsor/game/themes`);
  }
  getTheme(id: number) {
    return this.get<any>(`/sponsor/game/${id}/theme_game`);
  }

  createTheme(body: any) {
    return this.post<any>(`/sponsor/game/themes`, body);
  }

  updateTheme(id: number, body: any) {
    return this.put<any>(`/sponsor/game/${id}/themes`, body);
  }
  // end

  getPackageSponsor(query): Observable<any> {
    return this.get<any>(`/sponsor`, { params: query });
  }

  getObejctLanding(): Observable<ResponseModel> {
    return this.get<ResponseModel>(`/campaign/schema`);
  }

  actionWhiteList(id: number, query: any, body?: any): Observable<any> {
    return this.post<any>(`/campaign/${id}/customers/${query}`, body)
  }

  postFile(fileToUpload: File, id: any, params?: any): Observable<any> {
    const endpoint = `/campaign/${id}/upload-customers`;
    const formData: FormData = new FormData();
    formData.append('name_file', fileToUpload);
    return this
      .post(endpoint, formData, { params })
      .pipe(map((res) => res));
  }
}
