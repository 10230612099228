import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2, SimpleChanges,
    ViewChild
} from "@angular/core";
import { CommonService } from "../../services/common/common.service";

@Component({
    selector: 'app-ui-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})

export class UIModalComponent implements OnInit, OnDestroy, OnChanges {
    isCheckAccountVietel = false;
    @Input() show: boolean;
    @Input() autoHeight = false;
    @Input() titleModal;
    @Input() isScenario = true;
    @Input() isSuccess = true;
    @Input() isWaring = true;
    @Input() disableClickOutside = true;
    @Input() customCssAnimation: {
        duration: {
            show: number,
            hide: number
        },
        entranceAnimation: string,
        exitAnimation: string
    } = {
        duration: {
            show: 500,
            hide: 100
        },
        entranceAnimation: 'fadeInDown',
        exitAnimation: 'fadeOutUp'
    };
    @Input() height: number;
    @Input() width: number;
    @Input() padding: number;
    @Input() fullWidth = false;
    @Input() hiddenHeader = false;
    @Input() hiddenFooter = false;
    @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
    // tslint:disable-next-line
    @Output('close') closeNext: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('uiModal', { static: false }) private uiModal: ElementRef;
    @ViewChild('uiModalWindow', { static: false }) private uiModalWindow: ElementRef;

    listenForOutsideClick = false;
    timer: any;

    constructor(private renderer: Renderer2,   public commonService: CommonService) { }

    ngOnInit() { }

    ngOnChanges(changesObj: SimpleChanges) {
        this.isCheckAccountVietel = this.commonService.isCheckAccountByViettel();
        if (changesObj.show && this.uiModalWindow) {
            clearTimeout(this.timer);

            const entranceAnimationClass = this.customCssAnimation.entranceAnimation;
            const exitAnimationClass = this.customCssAnimation.exitAnimation;
            const animationDurationForShow = this.customCssAnimation.duration.show;
            const animationDurationForHide = this.customCssAnimation.duration.hide;

            if (changesObj.show.currentValue === true) {
                this.renderer.removeClass(this.uiModal.nativeElement, 'display-none');
                this.renderer.addClass(this.uiModalWindow.nativeElement, entranceAnimationClass);

                this.timer = setTimeout(() => {
                    this.renderer.removeClass(this.uiModalWindow.nativeElement, entranceAnimationClass);
                    this.listenForOutsideClick = true;
                }, animationDurationForShow);
            } else {
                this.renderer.addClass(this.uiModalWindow.nativeElement, exitAnimationClass);

                this.timer = setTimeout(() => {
                    this.renderer.addClass(this.uiModal.nativeElement, 'display-none');
                    this.renderer.removeClass(this.uiModalWindow.nativeElement, exitAnimationClass);
                    this.listenForOutsideClick = false;
                }, animationDurationForHide);
            }
        }
    }

    ngOnDestroy() {
        clearTimeout(this.timer);
    }

    close() {
        this.show = false;
        this.closeNext.emit();
        this.showChange.emit(false);
    }

    open() {
        this.show = true;
        this.closeNext.emit();
        this.showChange.emit(true);
    }

    onClickOutsideComponent() {
        if (this.disableClickOutside) {
            return;
        }

        this.closeEvent.emit(true);
        this.listenForOutsideClick = false;
        this.close();
    }
}
